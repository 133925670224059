// http://www.loc.gov/standards/iso639-2/php/code_list.php

import { CustomLocale } from '../enums/language-code.enum';

import * as English from './locales/en/common.json';
import * as French from './locales/fr/common.json';
import * as Spanish from './locales/es/common.json';

// Availables Languages
export const Locales = new Map<CustomLocale, any>([
  [CustomLocale.EN, English],
  [CustomLocale.FR, French],
  [CustomLocale.ES, Spanish],
]);
