import { Component, DestroyRef, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AppState } from '../../ngrx';
import * as globalActions from '../../ngrx/global/global.actions';
import { CustomLocale } from '../../enums/language-code.enum';
import {
  getCurrentLang,
  getCurrentTheme,
} from '../../ngrx/global/global.selectors';
import { Theme } from '../../enums/theme.enum';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import { getLabelFromLocale } from '../../utils/locale/locale.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    MatToolbar,
    NgIf,
    MatButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    NgFor,
    MatMenuItem,
  ],
})
export class NavBarComponent {
  public currentTheme!: string;
  public currentLang!: string;
  public currentLangLabel!: string;
  public languages: {
    translatedLang: string;
    internationalLang: string;
    code: CustomLocale;
  }[] = [];
  public themes!: Theme[];

  constructor(private _store: Store<AppState>) {
    this.themes = Object.values(Theme);

    // Observables from NgRx store
    this._initNgRxListeners(inject(DestroyRef));
  }

  // -------------------------------
  // Public
  // -------------------------------

  public onSetTheme(newTheme: Theme): void {
    this._store.dispatch(
      globalActions.setCurrentTheme({ currentTheme: newTheme }),
    );
  }

  public onSetLanguage(newLanguage: CustomLocale): void {
    this._store.dispatch(
      globalActions.setCurrentLang({ currentLang: newLanguage }),
    );
  }

  public onImgError(event: Event): void {
    (event.target as HTMLImageElement).style.display = 'none';
  }

  public onImgLoad(event: Event): void {
    (event.target as HTMLImageElement).style.display = 'initial';
  }

  /**
   * Used in order to manage differents img formats (png, svg, ...)
   * @returns
   */
  public getPartnerLogo(): string {
    let logoPath: string = `assets/themes/${this.currentTheme}/logo`;
    let extension: string = '.png';

    switch (this.currentTheme) {
      case Theme.THEME_LIGHT_VOLARIS:
        extension = '.svg';
        break;
    }

    return logoPath + extension;
  }

  public getDisplayedLanguageCode(lang: string): string {
    return lang.substring(0, 2).toUpperCase();
  }

  public isDevEnvironment(): boolean {
    return environment.local || environment.development || environment.mock;
  }

  // -------------------------------
  // Private
  // -------------------------------

  private _initNgRxListeners(ref: DestroyRef): void {
    this._store
      .pipe(select(getCurrentTheme), takeUntilDestroyed(ref))
      .subscribe((currentTheme: Theme) => {
        // Remove old theme
        document.body.classList.remove(this.currentTheme);

        // Add the new theme
        this.currentTheme = currentTheme;
        document.body.classList.add(currentTheme);
      });

    this._store
      .pipe(select(getCurrentLang), takeUntilDestroyed(ref))
      .subscribe((currentLang: CustomLocale) => {
        this.currentLang = currentLang;

        // Update the language selector
        this.languages = Object.values(CustomLocale).map((locale) => ({
          code: locale,
          translatedLang: getLabelFromLocale(locale, currentLang),
          internationalLang: getLabelFromLocale(locale, locale),
        }));

        // Update the selected language (label)
        this.currentLangLabel = getLabelFromLocale(currentLang, currentLang);
      });
  }
}
