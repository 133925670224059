<mat-toolbar class="fx-row fx-r-start-center fx-gap-16" color="primary">
  <div class="fx-row fx-r-start-center">
    <img
      class="logo"
      src="{{ getPartnerLogo() }}"
      (error)="onImgError($event)"
      (load)="onImgLoad($event)"
    />
  </div>

  <div class="fx-row fx-r-end-center">
    <ng-container *ngIf="isDevEnvironment()">
      <button
        mat-button
        [matMenuTriggerFor]="themeMenu"
        aria-label="Open Menu"
        *ngIf="currentTheme"
      >
        <mat-icon class="mr-8">palette</mat-icon>
        <span class="theme-name">{{ currentTheme }}</span>
      </button>

      <mat-menu #themeMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let theme of themes"
          attr.aria-label="Apply {{ theme }} theme"
          (click)="onSetTheme(theme)"
        >
          <mat-icon>palette</mat-icon>
          <span>{{ theme }}</span>
        </button>
      </mat-menu>
    </ng-container>

    <button
      mat-button
      [matMenuTriggerFor]="languageMenu"
      data-testid="languageMenu"
    >
      <b class="capitalize">{{ currentLangLabel }}</b>
    </button>

    <mat-menu #languageMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of languages"
        [attr.data-testid]="language.code"
        (click)="onSetLanguage(language.code)"
        attr.aria-label="{{ language.translatedLang }}"
        class="mb-16"
      >
        <div class="fx-column fx-c-start-center capitalize">
          <b class="fx-auto">{{ language.translatedLang }}</b>
          <span class="fx-auto small-text">{{
            language.internationalLang
          }}</span>
        </div>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
