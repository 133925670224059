<div class="layout-container fx-column fx-c-stretch-stretch">
  <ng-container *ngIf="isLoadingConfiguration">
    <div class="loader-container p-16 fx-column fx-c-center-center">
      <mat-spinner color="accent"></mat-spinner>
      <p>{{ 'COMMON.LOADING' | translate }}</p>
    </div>
  </ng-container>

  <app-navbar></app-navbar>

  <div class="content" id="scroll-anchor">
    <router-outlet></router-outlet>
  </div>
</div>
