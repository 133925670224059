export enum ExerciseActionStep {
  SEND_VERIFICATION_STEP = 'Send verification code',
  CHECK_VERIFICATION_STEP = 'Check verification code',
  CHOICE_STEP = 'Choice',

  HW_FORM_STEP = 'HW Form Step',
  HW_REFUND_STEP = 'HW Refund Step',
  HW_CONFIRMATION_STEP = 'HW Confirmation Step',

  REBOOKING_SEARCH_STEP = 'Rebooking Search Step',
  REBOOKING_REVIEW_STEP = 'Rebooking Review Step',
  REBOOKING_FORM_STEP = 'Rebooking Form Step',
  REBOOKING_CONFIRMATION_STEP = 'Rebooking Confirmation Step',
}
