import { createAction, props } from '@ngrx/store';
import { CustomLocale } from '../../enums/language-code.enum';
import { Theme } from '../../enums/theme.enum';

export const setCurrentLang = createAction(
  '[Global] Set the current language',
  props<{ currentLang: CustomLocale }>(),
);
export const setCurrentTheme = createAction(
  '[Global] Set the current theme',
  props<{ currentTheme: Theme }>(),
);
export const setIsLoadingConfiguration = createAction(
  '[Global] Set the isLoadingConfiguration boolean',
  props<{ isLoadingConfiguration: boolean }>(),
);
