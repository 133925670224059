import { Theme } from 'src/app/shared/enums/theme.enum';
import packageFile from '../../package.json';

export const environment = {
  production: false,
  staging: true,
  development: false,
  local: false,
  mock: false,
  defaultTheme: Theme.THEME_LIGHT_HTS,
  apiUrl: 'https://airlines-api.staging.hopper.com/airline/v1.1',
  version: packageFile.version,
  hyperwalletUrl:
    'https://uat-api.paylution.com/rest/widgets/transfer-methods/',
  defaultContactFormUrl: 'https://hopper.kustomer.help/lang/en_us/',
};
