import { createAction, props } from '@ngrx/store';
import {
  DgAlternative,
  DgCustomerItinerarySlice,
  DgExerciseCustomerResponse,
} from '@hts/partner-airlines-api-client';
import { ExerciseActionStep } from '../../enums/exercise-action-step.enum';

export const setIsLoading = createAction(
  '[Home] Set the state of IsLoading',
  props<{ isLoading: boolean }>(),
);
export const setStep = createAction(
  '[Home] Set the state of the step',
  props<{ step: ExerciseActionStep }>(),
);
export const setErrorCode = createAction(
  '[Home] Set the errorCode',
  props<{ errorCode: string }>(),
);
export const clearErrorCode = createAction('[Home] Clear the errorCode');
export const setUserEmail = createAction(
  '[Home] Set the userEmail',
  props<{ userEmail: string }>(),
);
export const setContractId = createAction(
  '[Home] Set the contractId',
  props<{ contractId: string }>(),
);
export const setSegmentId = createAction(
  '[Home] Set the segmentId',
  props<{ segmentId: string }>(),
);
export const setSessionId = createAction(
  '[Home] Set the sessionId',
  props<{ sessionId: string }>(),
);
export const setIsVerificationCodeAlreadySent = createAction(
  '[Home] Set the state of isVerificationCodeAlreadySent',
  props<{ isVerificationCodeAlreadySent: boolean }>(),
);
export const setVerificationCode = createAction(
  '[Home] Set the verificationCode',
  props<{ verificationCode: string }>(),
);
export const setCoveragePercentage = createAction(
  '[Home] Set the coveragePercentage',
  props<{ coveragePercentage: number }>(),
);
export const setExercise = createAction(
  '[Home] Set the exercise',
  props<{ exercise: DgExerciseCustomerResponse | undefined }>(),
);
export const setSelectedAlternative = createAction(
  '[Home] Set the selectedAlternative',
  props<{ selectedAlternative: DgAlternative | undefined }>(),
);
export const setSelectedSlice = createAction(
  '[Home] Set the selectedSlice',
  props<{ selectedSlice: DgCustomerItinerarySlice | undefined }>(),
);
export const setHasDisruption = createAction(
  '[Home] Set the hasDisruption',
  props<{ hasDisruption: boolean }>(),
);
export const setContactFormUrl = createAction(
  '[Home] Set the contactFormUrl',
  props<{ contactFormUrl: string }>(),
);
export const setIsFlowCompleted = createAction(
  '[Home] Set the isFlowCompleted',
  props<{ isFlowCompleted: boolean }>(),
);
export const setCurrencyCode = createAction(
  '[Home] Set the currencyCode',
  props<{ currencyCode: string }>(),
);
export const setPartnerId = createAction(
  '[Home] Set the partnerId',
  props<{ partnerId: string }>(),
);
export const setPartnerName = createAction(
  '[Home] Set the partnerName',
  props<{ partnerName: string }>(),
);
export const setMarketingCarrierCode = createAction(
  '[Home] Set the marketingCarrierCode',
  props<{ marketingCarrierCode: string }>(),
);
export const setServiceCap = createAction(
  '[Home] Set the serviceCap',
  props<{ serviceCap: string }>(),
);
export const setMinMinutesDelay = createAction(
  '[Home] Set the minMinutesDelay',
  props<{ minMinutesDelay: number }>(),
);
export const setMaxHoursBeforeDeparture = createAction(
  '[Home] Set the maxHoursBeforeDeparture',
  props<{ maxHoursBeforeDeparture: number }>(),
);
